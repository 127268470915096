import React, { useState, useEffect } from 'react';
import './RewardComponent.css';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    email: '',
    reward: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleRewardChange = (e) => {
    setFormData({
      ...formData,
      reward: e.target.value,
      email: e.target.value === 'migros' ? formData.email : ''
    });
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.reward === 'migros' && !formData.email) {
      setErrorMessage('Bitte gib deine E-Mail-Adresse für den Migros-Gutschein ein.');
      return;
    }
    let rewardChoice = 0;

    if (formData.reward === 'migros') {
      rewardChoice = 1;
    } else if (formData.reward === 'tree') {
      rewardChoice = 2;
    }

    // Make a request to send an SMS verification code
    const url_sms = 'https://tap-study.ch:8080/api/saveRewardChoice';
    const requestData = {
      userID: userID,
      email: formData.email,
      reward: rewardChoice,
    };

    fetch(url_sms, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 1) {
          setIsSubmitted(true);
        } else {
          setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      });

    console.log('Form submitted:', formData);
  };

  const [validLink, setValidLink] = useState(false);
  const [loading, setLoading] = useState(true);

  const searchParams = new URLSearchParams(document.location.search);
  let userID = searchParams.get('userID');

  useEffect(() => {
    if (userID) {
      const url_check = 'https://tap-study.ch:8080/api/checkValidityUser';
      const requestData = {
        userID: userID,
      };
      fetch(url_check, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log('Response Status:' + response.status);
          setValidLink(response.status === 1);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setValidLink(false);
          setLoading(false);
        });
    } else {
      setValidLink(false);
      setLoading(false);
    }
  }, [userID]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!validLink) {
    return (
      <div>
        <h1>Dieser Link ist leider falsch oder abgelaufen!</h1>
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="thank-you-page">
        <h1>Vielen Dank für deine Antwort!</h1>
      </div>
    );
  }

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit} className="form-content">
        <h2 className="form-title">Belohnungswahl</h2>
        <p className="form-description">
          Vielen Dank, dass du an der Umfrage teilgenommen hast. Als Dankeschön kannst du einen Einkaufsgutschein im Wert von 10 CHF/EUR erhalten. Alternativ kannst du dich anstelle des Gutscheins auch dafür entscheiden, dass wir einen Baum pflanzen. Wähle deine Option:
        </p>
        <div className="form-group">
          <label className="form-label">
            <input
              type="radio"
              name="reward"
              value="migros"
              checked={formData.reward === 'migros'}
              onChange={handleRewardChange}
              className="form-radio"
            />
            CHF 10 Migros Gutschein
          </label>
        </div>
        <div className="form-group">
          <label className="form-label">
            <input
              type="radio"
              name="reward"
              value="tree"
              checked={formData.reward === 'tree'}
              onChange={handleRewardChange}
              className="form-radio"
            />
            Einen Baum pflanzen
          </label>
        </div>

        {formData.reward === 'migros' && (
          <div className="form-group">
            <label className="form-label">Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Gib deine E-Mail-Adresse ein"
              className="form-input"
              required
            />
          </div>
        )}

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button type="submit" className="form-button">Absenden</button>
      </form>
    </div>
  );
};

export default FormComponent;
