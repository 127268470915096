import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Calendar from './Calendar.tsx'
import SignUpForm from './SignUpForm.tsx'
import PrivacyInfo from './PrivacyInfo.tsx'
import Reward from './Reward.tsx'
import { activityQuestionMapping } from './questionMapping.tsx';
import { personQuestionMapping } from './questionMapping.tsx';
import { placeQuestionMapping } from './questionMapping.tsx';
import { activityQuestionsList } from './questions.tsx';
import { personQuestionsList } from './questions.tsx';
import { placeQuestionsList } from './questions.tsx';
import Slider from './Slider'
import BatterySlider from './BatterySlider'


import backPic from './resources/Back-red.png';
import truePic from './resources/True-green.png';
import falsePic from './resources/False-red.png';

const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 99];

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    background-color: var(--unibas-mint-hell);
  }
`;

interface ItemProps {
    id: number;
    text: string;
    pictureUrl: string;
    onClick: () => void;
}

interface QuestionProps {
    id: number;
    text: string;
    items: ItemProps[];
    
}

export function Question({id, text, items}: QuestionProps) {
    return (
        <div className='question'>
            <div className='question-id'>{id}</div>
            <div className='question-text'>{text}</div>
            <div className='question-items'>
                {items.map((item) => (
                    <Item key={item.id} id={item.id} text={item.text} pictureUrl={item.pictureUrl} onClick={item.onClick}/>
                ))}
            </div>
        </div>
    );
}

export function Item({id, text, pictureUrl, onClick}: ItemProps) {
    
    return (
        <div className='item' onClick={onClick}>
            <div className='item-id'>{id}</div>
            <div className='item-picture'><img src={pictureUrl} alt=""/></div>
            <div className='item-text'>{text}</div>
        </div>
    );
}

export default function main() {
    return (
        <Routes>
            <Route path='/survey2' Component={Survey}></Route>
            <Route path='/survey1' Component={Calendar}></Route>
            <Route path='/signup' Component={SignUpForm}></Route>
            <Route path='/privacy' Component={PrivacyInfo}></Route> 
            <Route path='/reward' Component={Reward}></Route>
        </Routes>

    );

}

export function Survey() {

    const [apiResponse, setApiResponse] = useState('');
    
    interface activityAnswerHistoryProps {
        questionHistory: number[];
        answerIdHistory: number[];
        answerTextHistory: string[];
    }

    interface personAnswerHistoryProps {
        questionHistory: number[];
        answerIdHistory: number[];
        answerTextHistory: string[];
    }

    interface placeAnswerHistoryProps {
        questionHistory: number[];
        answerIdHistory: number[];
        answerTextHistory: string[];
    }

    const [activityAnswerHistory, setActivityAnswerHistory] = useState({
        questionHistory: [],
        answerIdHistory: [],
        answerTextHistory: []
    });

    const [personAnswerHistory, setPersonAnswerHistory] = useState({
        questionHistory: [],
        answerIdHistory: [],
        answerTextHistory: []
    });

    const [placeAnswerHistory, setPlaceAnswerHistory] = useState({
        questionHistory: [],
        answerIdHistory: [],
        answerTextHistory: []
    });

    const [activityAnswerHistoryArray, setactivityAnswerHistoryArray] = useState<Array<activityAnswerHistoryProps>>([]);
    const [personAnswerHistoryArray, setPersonAnswerHistoryArray] = useState<Array<personAnswerHistoryProps>>([]);
    const [placeAnswerHistoryArray, setPlaceAnswerHistoryArray] = useState<Array<placeAnswerHistoryProps>>([]);
    const [pastDuration, setPastDuration] = useState(0);
    const [futureDuration, setFutureDuration] = useState(0);
    const [isDigital, setIsDigital] = useState<number[]>([]);


    const [selfDetermined, setSelfDetermined] = useState('');
    const [happiness, setHappiness] = useState(0);
    const [energy, setEnergy] = useState(0);
    const [revertSurvey, setRevertSurvey] = useState(false);
    
    const [validLink, setValidLink] = useState(false);
    const [loading, setLoading] = useState(true);

    console.log("Activities: ", activityAnswerHistoryArray);
    console.log("Persons: ", personAnswerHistoryArray);
    console.log("Places: ", placeAnswerHistoryArray);
    console.log("Digital: ", isDigital);

    const [currentSurvey, setCurrentSurvey] = useState(0);

    const searchParams = new URLSearchParams(document.location.search)

    let userID: string|null = searchParams.get('userID');
    let surveyID: string|null = searchParams.get('surveyID');

    useEffect(() => {
        if (userID) {
        const url_check = 'https://tap-study.ch:8080/api/checkValiditySurvey2';
        const requestData = {
            userID: userID,
            surveyID: surveyID
        };

        fetch(url_check, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
        .then(response => response.json())
        .then((response: {status: number, message: string}) => {
            console.log('Response Status:' + response.status);
            if (response.status === 1) {
            setValidLink(true); // Set the link as valid
            } else {
            setValidLink(false); // Invalid link
            }
            setLoading(false); // Stop loading once response is received
        })
        .catch((error) => {
            console.error('Error:', error);
            setValidLink(false); // Set as invalid on error
            setLoading(false);
        });
        } else {
            setValidLink(false);
            setLoading(false);
        }
    }, [userID]); // Fetch validity check only on first load

    // If loading, show a loading message
    if (loading) {
        return (<div>Loading...</div>);
    }

    // If the link is invalid, show an error message
    if (!validLink) {
        return (
        <div>
            <h1>Dieser Link ist leider falsch oder abgelaufen!</h1>
        </div>
        );
    }

    if (currentSurvey == -1) {

        const url_places = 'https://tap-study.ch:8080/api/submitAnswer';
        const requestData = {
            userID: userID, // Make sure this is structured as expected by the backend
            surveyID: surveyID,
            activityAnswerHistory: activityAnswerHistoryArray,
            personAnswerHistory: personAnswerHistoryArray,
            placeAnswerHistory: placeAnswerHistoryArray,
            pastDuration: pastDuration,
            futureDuration: futureDuration,
            selfDetermined: selfDetermined,
            happiness: happiness,
            energy: energy,
            isDigital: isDigital
        };

        fetch(url_places, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
        .then(response => response.json())
        .then((response: {Status: number, message: string}) => 
        setApiResponse(response.message))
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
        setCurrentSurvey(-2);
    }

    const saveActivityAnswerHistory = (action: number ) => {
        if (action == 1) {
            setactivityAnswerHistoryArray(prevState => [...prevState, activityAnswerHistory]);
        } else {
            setactivityAnswerHistoryArray(prevState => prevState.slice(0, prevState.length - 1));
        }
    }


    const savePersonAnswerHistory = ( action: number ) => {
        if (action == 1) {
            setPersonAnswerHistoryArray(prevState => [...prevState, personAnswerHistory]);
        } else {
            setPersonAnswerHistoryArray(prevState => prevState.slice(0, prevState.length - 1));
        }
    }

    const savePlaceAnswerHistory = ( action: number ) => {
        if (action == 1) {
            setPlaceAnswerHistoryArray(prevState => [...prevState, placeAnswerHistory]);
        } else {
            setPlaceAnswerHistoryArray(prevState => prevState.slice(0, prevState.length - 1));
        }
    }
    
    const handleEmotions = (moodSliderValue, batterySliderValue) => {
        setHappiness(moodSliderValue);
        setEnergy(batterySliderValue);
        setCurrentSurvey(-1);
    }

    const handleFeedback = (feedback) => {
        const url_places = 'https://tap-study.ch:8080/api/submitFeedback';
        const requestData = {
            userID: userID, // Make sure this is structured as expected by the backend
            surveyID: surveyID,
            feedback: feedback
        };

        fetch(url_places, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
        .then(response => response.json())
        .then((response: {Status: number, message: string}) => 
        setApiResponse(response.message))
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
        setCurrentSurvey(99);
    }

    const handleBackClick = () => {
        setCurrentSurvey(2);
        savePlaceAnswerHistory(0);
        setPlaceAnswerHistory(prevState => ({
            questionHistory: prevState.questionHistory.slice(0, -1),
            answerIdHistory: prevState.answerIdHistory.slice(0, -1),
            answerTextHistory: prevState.answerTextHistory.slice(0, -1)
        }));
        return;
    }

    return (
    <>
        <GlobalStyle />
            {currentSurvey == 0 && (<div className='survey'>
                <Activity answerHistory={activityAnswerHistory} setAnswerHistory={setActivityAnswerHistory} currentSurvey={currentSurvey} previousAnswerHistoryArray={null} answerHistoryArray={activityAnswerHistoryArray} saveAnswerHistory={saveActivityAnswerHistory} setCurrentSurvey={setCurrentSurvey} revertSurvey={revertSurvey} setRevertSurvey={setRevertSurvey} questionsList={activityQuestionsList} questionMapping={activityQuestionMapping} pastDuration={pastDuration} futureDuration={futureDuration} setPastDuration={setPastDuration} setFutureDuration={setFutureDuration} setSelfDetermined={setSelfDetermined} setIsDigital={setIsDigital}/>
            </div>
            )}
            {currentSurvey == 1 && (
                <div className='survey'>
                    <Activity answerHistory={personAnswerHistory} setAnswerHistory={setPersonAnswerHistory} currentSurvey={currentSurvey} previousAnswerHistoryArray={activityAnswerHistoryArray} answerHistoryArray={personAnswerHistoryArray} saveAnswerHistory={savePersonAnswerHistory} setCurrentSurvey={setCurrentSurvey} revertSurvey={revertSurvey} setRevertSurvey={setRevertSurvey}  questionsList={personQuestionsList} questionMapping={personQuestionMapping} pastDuration={pastDuration} futureDuration={futureDuration} setPastDuration={setPastDuration} setFutureDuration={setFutureDuration} setSelfDetermined={setSelfDetermined} setIsDigital={setIsDigital}/>
                </div>
            )}
            {currentSurvey == 2 && (
                <div className='survey'>
                    <Activity answerHistory={placeAnswerHistory} setAnswerHistory={setPlaceAnswerHistory} currentSurvey={currentSurvey} previousAnswerHistoryArray={personAnswerHistoryArray} answerHistoryArray={placeAnswerHistoryArray} saveAnswerHistory={savePlaceAnswerHistory} setCurrentSurvey={setCurrentSurvey} revertSurvey={revertSurvey} setRevertSurvey={setRevertSurvey}  questionsList={placeQuestionsList} questionMapping={placeQuestionMapping} pastDuration={pastDuration} futureDuration={futureDuration} setPastDuration={setPastDuration} setFutureDuration={setFutureDuration} setSelfDetermined={setSelfDetermined} setIsDigital={setIsDigital}/>
                </div>
            )}
            {currentSurvey == 3 && (
                <div className='survey'>
                    <form 
                    onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData(e.target as HTMLFormElement);
                    const moodSliderValue = formData.get('moodSliderValue');
                    const batterySliderValue = formData.get('batterySliderValue');
                    handleEmotions(moodSliderValue, batterySliderValue);
                    }} 
                    className="durationForm"
                >
                    <div className="formField">
                    <div className='survey-container'>
                        <div className='question-text'>Wie zufrieden fühlst du dich gerade?</div>
                        <Slider/>
                        <br></br><br></br><br></br><br></br>
                    </div>
                    </div>
                
                    <div className="formField">
                    <div className='question-text'>Wie energetisch fühlst du dich gerade?</div>
                    <BatterySlider/>
                    </div>
                
                    <div className="submitContainer">
                    <button type="submit" className="okButton">OK</button>
                    </div>
                </form>
                <div className="survey-back-button" id='clickableBack' onClick={handleBackClick}>
                    <img className='backPic' src={backPic} alt=""/>
                </div>
                </div>
                
            )}
            {currentSurvey == 99 && (
                <div className='survey'>
                    <div className='question-text'>Vielen Dank für dein Feedback!</div>
                </div>
            )}
            {currentSurvey == -2 && apiResponse != '' && (
                <div className='survey'>
                <form 
                onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target as HTMLFormElement);
                const feedback = formData.get('feedback');
                handleFeedback(feedback);
                }} 
                className="durationForm"
            >
                <div className="formField">
                <div className='survey-container'>
                    <div className='feedback-text'>Wir befinden uns momentan in der Testphase. Bitte teile uns Probleme mit, die dir beim Ausfüllen des Fragebogens aufgefallen sind.</div>
                    <textarea id="feedback" name="feedback" rows={5} placeholder="Dein Feedback"></textarea>
                </div>
                </div>
            
                <div className="submitContainer">
                <button type="submit" className="okButton">Einreichen</button>
                </div>
            </form>
            </div>
            )}
        <div/>
    </>
    );
}

export function Activity({ answerHistory, setAnswerHistory, currentSurvey, previousAnswerHistoryArray, answerHistoryArray, saveAnswerHistory, setCurrentSurvey, revertSurvey, setRevertSurvey, questionsList, questionMapping, pastDuration, futureDuration, setPastDuration, setFutureDuration, setSelfDetermined, setIsDigital}) {
    
    // Assign the handleClick function to each item
    const arrayQuestions = questionsList.map(question => ({
        ...question,
        items: question.items.map(item => ({
            ...item,
            onClick: () => {
                if (item.id === 0) {
                    handleCustomInputClick();
                } else {
                    handleClick(question.id, item.id);
                }
            }
        }))
    }));

    
    const [currentQuestion, setCurrentQuestion] = useState(() => arrayQuestions[0]);

    const [showCustomInput, setShowCustomInput] = useState(false);
    const [customInputValue, setCustomInputValue] = useState('');

    const[isRepeatActivity, setIsRepeatActivity] = useState(false);

    const[clickedOnce, setClickedOnce] = useState(false);

    const [durationScreenPage, setDurationScreenPage] = useState(1);

    const [tempPastDuration, setTempPastDuration] = useState(null);
    const [tempFutureDuration, setTempFutureDuration] = useState(null);
    
    
    function repeatActivity() {
        setIsRepeatActivity(true);
        setCurrentQuestion(arrayQuestions[0]);
        setAnswerHistory(prevState => ({
            questionHistory: [],
            answerIdHistory: [],
            answerTextHistory: []
        }));
        setTempEndOfActivity(false);
    }

    async function endActivity() {
        setCurrentQuestion(arrayQuestions[0]);
        setAnswerHistory(prevState => ({
            questionHistory: [],
            answerIdHistory: [],
            answerTextHistory: [],
        }));
        if (currentSurvey == 0) {
            setCurrentSurvey(1);
        } else if (currentSurvey == 1) {
            setCurrentSurvey(2);
        }
    }

    function nextQuestion(questionId: number, itemId: number): number {
        const key = `${questionId}-${itemId}`;
        const nextQuestionNumber = questionMapping[key] || null;
        return nextQuestionNumber;
    }

    const handleClick = (questionId, itemId) => {
        const nextQuestionId = nextQuestion(questionId, itemId);
        const itemText = questionsList[questionId - 1].items.find(item => item.id === itemId)?.text || '';
    
        setAnswerHistory(prevState => ({
            questionHistory: [...(prevState.questionHistory || []), questionId],
            answerIdHistory: [...(prevState.answerIdHistory || []), itemId],
            answerTextHistory: [...(prevState.answerTextHistory || []), itemText]
        }));
    
        if (nextQuestionId === null) {
            // Indicate that there's no next question, could use a separate state variable
            setTempEndOfActivity(true);
            //if activity is 'lesen', set digital screen to true
            if (itemText === 'Lernen' || itemText === 'Meeting' || itemText === 'Hausaufgaben' || itemText === 'Überstunden' || itemText === 'Gruppenarbeit' || itemText === 'Vor-, Nachbereiten'
                || itemText === 'Finanzen, Steuern' || itemText === 'Instrument üben' || itemText === 'Malen / Zeichnen' || itemText === 'Kleider kaufen' || itemText === 'Schuhe kaufen' || itemText === 'Kosmetik kaufen'
                || itemText === 'Games und Zubehör kaufen' || itemText === 'Möbel kaufen' || itemText === 'Streiten' || itemText === 'Lesen' || itemText === 'Chillen' || itemText === 'Journaling' || itemText === 'Rätsel lösen'
                || itemText === 'Instrument üben / Singen'   
            ) {
                setDigitalScreen(true);
            } else {
                setIsDigital(prevState => [...prevState, -1]);
                setDigitalScreen(false);
                if (currentSurvey == 0 && !doneFirstActivity) {
                    setDoneFirstActivity(true);
                    setDurationScreen(true);
                }
            }
            return;
        }
    
        setCurrentQuestion(arrayQuestions[nextQuestionId - 1]);
    };
    

    const [istempEndOfActivity, setTempEndOfActivity] = useState(false);
    const [doneFirstActivity, setDoneFirstActivity] = useState(false);
    const [durationScreen, setDurationScreen] = useState(false);
    const [digitalScreen, setDigitalScreen] = useState(false);

    console.log("Digital Screen", digitalScreen);
    console.log("Duration Screen", durationScreen);

    if (revertSurvey && !clickedOnce) {
        setTempEndOfActivity(true);
        setClickedOnce(true);
        setRevertSurvey(false);
        setAnswerHistory(answerHistoryArray[answerHistoryArray.length - 1]);
        setCurrentQuestion(arrayQuestions[answerHistoryArray[answerHistoryArray.length - 1].questionHistory[answerHistoryArray[answerHistoryArray.length - 1].questionHistory.length - 1] - 1]);
    }
    


    useEffect(() => {
        if (istempEndOfActivity && answerHistory.questionHistory.length > 0) {
            saveAnswerHistory(1);
            if (currentSurvey == 2) {
                setCurrentSurvey(3);
            }
        }
    }, [istempEndOfActivity]);

    console.log("Current Survey: ", currentSurvey);   

    const handleBackClick = () => {
        if (durationScreenPage === 2) {
            setDurationScreenPage(1);
            return;
        }

        if (showCustomInput) {
            setShowCustomInput(false);
            return;
        }

        if (currentSurvey == 0) {
            if (durationScreen) {
                console.log("Resetting");
                saveAnswerHistory(0);
                setDurationScreen(false);
                setDoneFirstActivity(false);
                setTempEndOfActivity(false);
                setAnswerHistory(prevState => ({
                    questionHistory: prevState.questionHistory.slice(0, -1),
                    answerIdHistory: prevState.answerIdHistory.slice(0, -1),
                    answerTextHistory: prevState.answerTextHistory.slice(0, -1)
                }));
                //remove last element from isDigital array
                setIsDigital(prevState => prevState.slice(0, -1));
                return;
            } else if (istempEndOfActivity && answerHistoryArray.length == 1 && !digitalScreen) {
                setDurationScreen(true);
                return;
            } else if (istempEndOfActivity) {
                saveAnswerHistory(0);
                setAnswerHistory(prevState => ({
                    questionHistory: prevState.questionHistory.slice(0, -1),
                    answerIdHistory: prevState.answerIdHistory.slice(0, -1),
                    answerTextHistory: prevState.answerTextHistory.slice(0, -1)
                }));
                setTempEndOfActivity(false);
                setIsDigital(prevState => prevState.slice(0, -1));
                return;
            } else if ((!answerHistory.questionHistory || answerHistory.questionHistory.length === 0) && answerHistoryArray.length > 0) {
                setTempEndOfActivity(true);
                saveAnswerHistory(0);
                setAnswerHistory(answerHistoryArray[answerHistoryArray.length - 1]);
                setCurrentQuestion(arrayQuestions[answerHistoryArray[answerHistoryArray.length - 1].questionHistory[answerHistoryArray[answerHistoryArray.length - 1].questionHistory.length - 1] - 1]);
                return;
            }
        } else if (currentSurvey == 1) {
            if (istempEndOfActivity) {
                saveAnswerHistory(0);
                setAnswerHistory(prevState => ({
                    questionHistory: prevState.questionHistory.slice(0, -1),
                    answerIdHistory: prevState.answerIdHistory.slice(0, -1),
                    answerTextHistory: prevState.answerTextHistory.slice(0, -1)
                }));
                setTempEndOfActivity(false);
                return;
            } else if ((!answerHistory.questionHistory || answerHistory.questionHistory.length === 0) && answerHistoryArray.length > 0) {
                setTempEndOfActivity(true);
                saveAnswerHistory(0);
                setAnswerHistory(answerHistoryArray[answerHistoryArray.length - 1]);
                setCurrentQuestion(arrayQuestions[answerHistoryArray[answerHistoryArray.length - 1].questionHistory[answerHistoryArray[answerHistoryArray.length - 1].questionHistory.length - 1] - 1]);
                return;
            } else if ((!answerHistory.questionHistory || answerHistory.questionHistory.length === 0) && answerHistory.questionHistory.length === 0) {
                setCurrentSurvey(0);
                saveAnswerHistory(0);
                setTempEndOfActivity(true);
                setRevertSurvey(true);
                answerHistory = previousAnswerHistoryArray[previousAnswerHistoryArray.length - 1];
                console.log("Answer History: ", answerHistory);
                return;
            }
        } else if (currentSurvey == 2) {
            if ((!answerHistory.questionHistory || answerHistory.questionHistory.length === 0) && answerHistory.questionHistory.length === 0) {
                setCurrentSurvey(1);
                saveAnswerHistory(0);
                setTempEndOfActivity(true);
                setRevertSurvey(true);
                answerHistory = previousAnswerHistoryArray[previousAnswerHistoryArray.length - 1];
                console.log("Answer History: ", answerHistory);
                return;
            }
        }

        
        setAnswerHistory(prevState => ({
            questionHistory: prevState.questionHistory.slice(0, -1),
            answerIdHistory: prevState.answerIdHistory.slice(0, -1),
            answerTextHistory: prevState.answerTextHistory.slice(0, -1)
        }));
    
        setCurrentQuestion(arrayQuestions[answerHistory.questionHistory[answerHistory.questionHistory.length - 1] - 1]);
    };
    
    const handleFirstPageSubmit = (pastDuration, futureDuration) => {
        setTempPastDuration(pastDuration);
        setTempFutureDuration(futureDuration);
        setDurationScreenPage(2);
      };

    const handleCustomInputSubmit = () => {
        setAnswerHistory(prevState => ({
            questionHistory: [...(prevState.questionHistory || []), currentQuestion.id],
            answerIdHistory: [...(prevState.answerIdHistory || []), 0],
            answerTextHistory: [...(prevState.answerTextHistory || []), customInputValue]
        }));
        setShowCustomInput(false);
        setCustomInputValue('');
        setTempEndOfActivity(true);

        // Indicate that there's no next question, could use a separate state variable
        setTempEndOfActivity(true);
        if (currentSurvey == 0 && !doneFirstActivity) {
            setDoneFirstActivity(true);
            setDurationScreen(true);
        }
        return;
    };

    const handleCustomInputClick = () => {
        setShowCustomInput(true);
    };

    const handleOther = () => {
        setShowCustomInput(true);
    }

    const handleDuration = (pastDuration, futureDuration, selfDetermined) => {
        setPastDuration(pastDuration);
        setFutureDuration(futureDuration);
        setSelfDetermined(selfDetermined);
        setDurationScreen(false);
        setDurationScreenPage(1);
        return;
    }

    const handleDigital = (isDigital) => {
        isDigital = parseInt(isDigital)
        setIsDigital(prevState => [...prevState, isDigital]);
        setDigitalScreen(false);
        if (currentSurvey == 0 && !doneFirstActivity) {
            setDoneFirstActivity(true);
            setDurationScreen(true);
        }
    }

    console.log("LENGTH: ", answerHistoryArray.length);

    return (
        <>
        <div className='survey-container'>
            {!showCustomInput && !istempEndOfActivity && (
                <Question id={currentQuestion.id} text={currentQuestion.text} items={currentQuestion.items} />
            )}
            {showCustomInput && (
                <div className="custom-input-container">
                    <div className='question-text'>Andere: Was genau?</div>
                    <input className='custom-input'
                        type="text" 
                        value={customInputValue} 
                        onChange={(e) => setCustomInputValue(e.target.value)}
                        placeholder="Enter your answer"
                    />
                    <button className='submit-button' onClick={handleCustomInputSubmit}>Submit</button>
                </div>
            )}
            {istempEndOfActivity && (currentSurvey == 0) && (digitalScreen) && (
            <form
            onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target as HTMLFormElement);
                const isDigital = formData.get('isDigital');
                handleDigital(isDigital);
            }}
            className="durationForm"
            >

            {/* Digital Question */}
            <div className="formField">
            <label className="formLabel">Wie führst du die Tätigkeit <strong>hauptsächlich</strong> aus?</label>
            <div className="radioGroup">
                <div style={{ marginBottom: "15px" }}>
                    <input
                        type="radio"
                        id="optionA"
                        name="isDigital"
                        value="1"
                        required
                        style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="optionA" style={{ fontSize: "14px", color: "#555" }}>
                        <strong>a) Digital</strong><br></br>
                        (mithilfe von Computern oder anderen digitalen Geräten)
                    </label>
                </div>

                <div style={{ marginBottom: "15px" }}>
                    <input
                        type="radio"
                        id="optionB"
                        name="isDigital"
                        value="0"
                        required
                        style={{ marginRight: "10px" }}
                    />
                    <label htmlFor="optionB" style={{ fontSize: "14px", color: "#555" }}>
                        <strong>b) Analog</strong><br></br>
                        (in der realen physischen Welt)
                    </label>
                </div>
            </div>
            </div>

            {/* Submit Button */}
            <div className="submitContainer">
                <button type="submit" className="okButton">OK</button>
            </div>
            </form>
            )
            }
            {istempEndOfActivity && (currentSurvey == 0) && (durationScreen) && (answerHistoryArray.length == 1) && (durationScreenPage == 1) && (
                <form
                onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData(e.target as HTMLFormElement);
                    const pastDuration = formData.get('pastDuration');
                    const futureDuration = formData.get('futureDuration');
                    handleFirstPageSubmit(pastDuration, futureDuration);
                }}
                className="durationForm"
                >
                {/* Past Duration Field */}
                <div className="formField">
                    <label htmlFor="pastDuration" className="formLabel">Seit ungefähr wie vielen Minuten führst du diese Tätigkeit aus?</label>
                    <select name="pastDuration" id="pastDuration" required className="formSelect">
                    <option value="" disabled selected>Bitte auswählen</option>
                    {minutes.map((minute, index) => (
                        minute === 0 ? (
                        <option key={index} value={minute}>Seit weniger als 5 Minuten</option>
                        ) : minute === 99 ? (
                        <option key={index} value={minute}>Seit mehr als 60 Minuten</option>
                        ) : (
                        <option key={index} value={minute}>Seit circa {minute} Minuten</option>
                        )
                    ))}
                    </select>
                </div>
                <br />

                {/* Future Duration Field */}
                <div className="formField">
                    <label htmlFor="futureDuration" className="formLabel">Wie lange wird die Tätigkeit ungefähr noch dauern?</label>
                    <select name="futureDuration" id="futureDuration" required className="formSelect">
                    <option value="" disabled selected>Bitte auswählen</option>
                    {minutes.map((minute, index) => (
                        minute === 0 ? (
                        <option key={index} value={minute}>Weniger als 5 Minuten</option>
                        ) : minute === 99 ? (
                        <option key={index} value={minute}>Mehr als 60 Minuten</option>
                        ) : (
                        <option key={index} value={minute}>Circa {minute} Minuten</option>
                        )
                    ))}
                    </select>
                </div>
                <br />

                {/* Submit Button */}
                <div className="submitContainer">
                    <button type="submit" className="okButton">OK</button>
                </div>
                </form>
            )
            }
            {istempEndOfActivity && (currentSurvey == 0) && (durationScreen) && (answerHistoryArray.length == 1) && (durationScreenPage == 2) && (
                <form
                onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData(e.target as HTMLFormElement);
                    const selfDetermined = formData.get('activityType');
                    handleDuration(tempPastDuration, tempFutureDuration, selfDetermined);
                }}
                className="durationForm"
                >
                {/* Selbstbestimmung Question */}
                <div className="formField" style={{}}>
                    <label className="formLabel">Inwieweit war die Tätigkeit, die du gerade ausgeführt hast, selbstbestimmt? <br></br>(Es ist uns wichtig, wie du die Situation wahrnimmst. Es gibt kein richtig oder falsch - wähle einfach die Antwort, die am besten zu deiner Erfahrung passt.)</label>
                    <div className="radioGroup">
                        <div style={{ marginBottom: "15px" }}>
                            <input
                                type="radio"
                                id="optionA"
                                name="activityType"
                                value="selbstbestimmt"
                                required
                                style={{ marginRight: "10px" }}
                            />
                            <label htmlFor="optionA" style={{ fontSize: "14px", color: "#555" }}>
                                <strong>a) Selbstbestimmt</strong>
                                <br />
                                <span style={{ paddingLeft: "20px", display: "inline-block" }}>
                                    → Ich habe die Tätigkeit komplett aus eigenem Antrieb gewählt.
                                </span>
                            </label>
                        </div>

                        <div style={{ marginBottom: "15px" }}>
                            <input
                                type="radio"
                                id="optionB"
                                name="activityType"
                                value="eher selbstbestimmt"
                                required
                                style={{ marginRight: "10px" }}
                            />
                            <label htmlFor="optionB" style={{ fontSize: "14px", color: "#555" }}>
                                <strong>b) Eher selbstbestimmt</strong>
                                <br />
                                <span style={{ paddingLeft: "20px", display: "inline-block" }}>
                                    → Ich habe die Tätigkeit grösstenteils selbst entschieden, es gab aber leichten ausseren Einfluss.
                                </span>
                            </label>
                        </div>

                        <div style={{ marginBottom: "15px" }}>
                            <input
                                type="radio"
                                id="optionC"
                                name="activityType"
                                value="eher fremdbestimmt"
                                required
                                style={{ marginRight: "10px" }}
                            />
                            <label htmlFor="optionC" style={{ fontSize: "14px", color: "#555" }}>
                                <strong>c) Eher fremdbestimmt</strong>
                                <br />
                                <span style={{ paddingLeft: "20px", display: "inline-block" }}>
                                    → Die Tätigkeit wurde grösstenteils von anderen bestimmt, ich hatte aber etwas Mitbestimmung.
                                </span>
                            </label>
                        </div>

                        <div style={{ marginBottom: "15px" }}>
                            <input
                                type="radio"
                                id="optionD"
                                name="activityType"
                                value="fremdbestimmt"
                                required
                                style={{ marginRight: "10px" }}
                            />
                            <label htmlFor="optionD" style={{ fontSize: "14px", color: "#555" }}>
                                <strong>d) Fremdbestimmt</strong>
                                <br />
                                <span style={{ paddingLeft: "20px", display: "inline-block" }}>
                                    → Die Tätigkeit wurde komplett von anderen entschieden, ich hatte keine Wahl.
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="submitContainer">
                    <button type="submit" className="okButton">OK</button>
                </div>
                </form>
            )
            }

            {istempEndOfActivity && (currentSurvey == 0) && (!durationScreen && !digitalScreen) && (
                <div className="custom-input-container">
                    <div className='question-text'>Führst du nebenbei weitere Tätigkeiten aus?</div>
                    <div className='question-items'>
                        <div className='item' onClick={repeatActivity}>
                            <div className='item-id'>{1}</div>
                            <div className='item-picture'><img src={truePic} alt=""/></div>
                            <div className='item-text'>{'Ja'}</div>
                        </div>
                        <div className='item' onClick={endActivity}>
                            <div className='item-id'>{0}</div>
                            <div className='item-picture'><img src={falsePic} alt=""/></div>
                            <div className='item-text'>{'Nein'}</div>
                        </div>
                    </div>
                </div>
            )}
            {istempEndOfActivity && (currentSurvey == 1) && (
                <div className="custom-input-container">
                    <div className='question-text'>Ist noch jemand weiteres bei dir?</div>
                    <div className='question-items'>
                        <div className='item' onClick={repeatActivity}>
                            <div className='item-id'>{1}</div>
                            <div className='item-picture'><img src={truePic} alt=""/></div>
                            <div className='item-text'>{'Ja'}</div>
                        </div>
                        <div className='item' onClick={endActivity}>
                            <div className='item-id'>{0}</div>
                            <div className='item-picture'><img src={falsePic} alt=""/></div>
                            <div className='item-text'>{'Nein'}</div>
                        </div>
                    </div>
                </div>
            )}
            {istempEndOfActivity && (currentSurvey == 2) && (
                <div className="custom-input-container">
                    <div className='question-text'>Danke für deine Teilnahme!</div>
                </div>
            )}
        </div>
        {((currentSurvey != 0 || answerHistoryArray.length != 0) || answerHistory.questionHistory.length > 0) && (<div className="survey-back-button" id='clickableBack' onClick={handleBackClick}>
            <img className='backPic' src={backPic} alt=""/>
        </div>)}
        </>
    );
}

